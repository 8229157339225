import { render, staticRenderFns } from "./passo8.vue?vue&type=template&id=71539555&scoped=true&"
import script from "./passo8.vue?vue&type=script&lang=js&"
export * from "./passo8.vue?vue&type=script&lang=js&"
import style0 from "./passo8.vue?vue&type=style&index=0&id=71539555&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71539555",
  null
  
)

export default component.exports