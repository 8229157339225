<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <Topo
        :titulo="`Evento - ${idEvento}`"
        @OpenMenuLateral="menuOpen = true"
      ></Topo>
      <b-row>
        <b-col>
          <div v-if="evento" class="container mt-3">
            <passo0
              v-if="passo == 0"
              :evento="evento"
              :temaCor="temaCor"
              @send="Salvar"
            />

            <passo1
              v-if="passo == 1"
              :evento="evento"
              :temaCor="temaCor"
              @send="Salvar"
            />

            <passo2
              v-if="passo == 2"
              :evento="evento"
              :temaCor="temaCor"
              @send="Salvar"
            />

            <passo3
              v-if="passo == 3"
              :evento="evento"
              :temaCor="temaCor"
              @send="Salvar"
            />

            <passo4
              v-if="passo == 4"
              :evento="evento"
              :temaCor="temaCor"
              @send="Salvar"
            />

            <passo5
              v-if="passo == 5"
              :evento="evento"
              :temaCor="temaCor"
              @send="Salvar"
            />

            <passo6
              v-if="passo == 6"
              :evento="evento"
              :temaCor="temaCor"
              @send="Salvar"
            />

            <passo7
              v-if="passo == 7"
              :evento="evento"
              :temaCor="temaCor"
              @send="Salvar"
            />

            <passo8
              v-if="passo == 8"
              :evento="evento"
              :temaCor="temaCor"
              @send="Salvar"
            />

            <passo9
              v-if="passo == 9"
              :evento="evento"
              :temaCor="temaCor"
              @send="Salvar"
            />

            <passo10
              v-if="passo == 10"
              :evento="evento"
              :temaCor="temaCor"
              @send="Salvar"
            />

            <div class="mt-3 mb-3 p-5"></div>
          </div>
        </b-col>
      </b-row>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import Topo from './TopoInterno';
import MenuRodape from './MenuRodape';
import ConteudoDash from './ConteudoDash';
import MenuLateral from './MenuLateral';
import service from '../services';
import moment from 'moment';

import passo0 from './FormEvento/passo0';
import passo1 from './FormEvento/passo1';
import passo2 from './FormEvento/passo2';
import passo3 from './FormEvento/passo3';
import passo4 from './FormEvento/passo4';
import passo5 from './FormEvento/passo5';
import passo6 from './FormEvento/passo6';
import passo7 from './FormEvento/passo7';
import passo8 from './FormEvento/passo8';
import passo9 from './FormEvento/passo9';
import passo10 from './FormEvento/passo10';

import colors from './../services/colors';
export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  watch: {
    deep: true,
    passo: function (newValue, oldValue) {
      console.log(this.passo, newValue);
    },
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      carregando: false,
      idEvento: this.$route.params.idEvento,
      evento: null,
      passo: this.$route.params.passo,
      temaCor: {},
      form: {},
    };
  },
  components: {
    Topo,
    ConteudoDash,
    MenuRodape,
    MenuLateral,
    passo0,
    passo1,
    passo2,
    passo3,
    passo4,
    passo5,
    passo6,
    passo7,
    passo8,
    passo9,
    passo10,
  },
  methods: {
    Salvar(form) {
      this.carregando = true;
      console.log(form);
      form.passo = this.passo;
      service
        .put('Eventos', 'Atualizar/' + this.idEvento, form, {})
        .then((res) => {
          this.carregando = false;
          this.$bvToast.toast('Dados Atualizados com sucesso', {
            title: 'Atenção!',
            solid: true,
            variant: 'success',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 1500,
          });
          this.passo = parseInt(this.passo) + 1;
          console.log(this.passo);
          this.$router.push(`/editarEvento/${this.idEvento}/${this.passo}`);
        })
        .catch((e) => {
          this.carregando = false;
          this.$bvToast.toast(e, {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });
        });
    },
    buscarEvento() {
      this.carregando = true;
      this.evento = {};
      var obj = {};
      obj.idEvento = this.idEvento;
      obj.idAssociado = this.decoded.id_prospect;
      service.post('Eventos', 'list', obj).then((res) => {
        console.log(res);
        this.evento = res.data.eventos[0];
        console.log(this.evento);
        this.carregando = false;
      });
    },
    verificaToken() {
      this.temaCor = colors.getColorsTema();
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
        console.log(this.decoded);
        this.buscarEvento();
      }
    },
  },
  mounted() {
    this.verificaToken();
  },
};
</script>

<style lang="scss" scoped>
.MeusDados {
  padding-bottom: 80px;
  label,
  label.d-block,
  .form-group {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    font-family: 'robotobold';
  }
}
</style>
