<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <div class="form" v-if="evento">
        <b-form @submit.stop.prevent="onSubmit" class="text-left">
          <div class="text-center">
            <font-awesome-icon :icon="['fa', 'check']" class="iconeSucesso" />
          </div>
          <h4>Parabéns</h4>

          <div class="alert alert-success legenda">
            Pré abertura de Evento Atualizada com sucesso!
          </div>
          <b-button
            pill
            ref="btnAvancar"
            size="lg"
            type="button"
            variant="info"
            class="btn btn-block mb-5"
            @click="continuar"
            :style="`background-color:${temaCor.botaoFormularios};`"
            >Voltar para Eventos</b-button
          >
        </b-form>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import service from '../../services';
import moment from 'moment';
import { Settings } from 'luxon';
Settings.defaultLocale = 'pt-br';
import locale from './../../locale';
import componenteUpFoto from './componente_upFoto';
export default {
  name: 'DashBoard',
  props: ['evento', 'temaCor'],
  watch: {
    evento(value) {
      // console.log("Chamou o preencher");
      this.preencheForm();
    },
    cep: function (newValue, oldValue) {
      console.log(newValue, oldValue, this.cep);
      if (this.cep)
        if (this.cep.length == 10) {
          this.buscarCep();
        }
    },
  },
  components: { componenteUpFoto },
  data() {
    return {
      render: false,
      exibeCheck: false,
      decoded: {},
      menuOpen: false,
      carregando: false,
      cep: null,
      encontrouCep: null,
      mostraErroGeo: false,
      modalFoto: false,
      modalTermosNaoPreenchidos: false,
      maxDate: moment().format(),
      estados: [],
      cidades: [],
      termos: [],
      termoSelecionado: {},
      exibeCheck: true,
      exibeForm: true,
      form: { utilizarDadosCondutor: null },
    };
  },
  methods: {
    getIP() {
      fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then((response) => {
          console.log(response.ip);
          this.form.ipAceite = response.ip;
        });
    },
    getLocation() {
      if (navigator.geolocation) {
        console.log('getLocation was called', navigator.geolocation);
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.positionError,
        );
      }
    },
    showPosition(position) {
      this.form.cordenadas = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        altitude: null,
        altitudeAccuracy: null,
        accuracy: 43,
      };
      console.log(
        'Latitude: ' +
          position.coords.latitude +
          '<br>Longitude: ' +
          position.coords.longitude,
      );
    },
    positionError() {
      console.log('erro');
      this.mostraErroGeo = true;
    },
    preencheAssinatura(obj) {
      this.modalFoto = false;
      console.log(obj, this.evento.idEvento);
      this.termos.forEach((element) => {
        console.log(element.id_assinatura, obj.foto.id_assinatura);
        if (element.id_assinatura == obj.foto.id_assinatura) {
          element.assinatura = obj.url;
          element.aprovada = null;
          element.atualizada = true;
          element.coordenadas = obj.coordenadas;
          console.log(element.assinatura);
        }
      });
      console.log(this.termos);
    },
    openModalFoto(item) {
      this.termoSelecionado = item;
      this.termoSelecionado.modeloContrato = this.modalFoto = true;
    },
    verificaDadosAssociado() {},
    async preencheForm() {
      if (this.evento.dadosCondutor && this.render == false) {
        //this.BuscarTermosEvento();
        var dadosCondutor = JSON.parse(this.evento.dadosCondutor);
        var dadosAssociado = this.evento.Eventos_Associado;
      } //else this.preencheForm();
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.form.termos = this.termos.filter(
          (x) => x.assinatura != null && x.atualizada == true,
        );
        this.form.idPessoa = this.evento.Eventos_Associado.idProspect;
        var termosSemAssinatura = this.termos.filter(
          (x) => x.nome == null || (x.assinatura == null && !x.atualizada),
        );

        if (termosSemAssinatura.length > 0) {
          this.modalTermosNaoPreenchidos = true;
          return;
        }
        this.$emit('send', this.form);
      });
    },
    continuar() {
      this.modalTermosNaoPreenchidos = false;
      this.$router.push(`/eventos`);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    BuscarTermosEvento() {
      this.carregando = true;
      this.render = true;
      this.termos = [];
      var obj = {};
      obj.idEvento = this.evento.idEvento;

      service.post('Eventos', 'BuscarTermosEvento', obj).then((res) => {
        console.log(res);
        this.termos = res.data;
        console.log(this.termos);
        if (this.termos.length == 0) {
          this.$router.push(`/editarEvento/${this.evento.idEvento}/11`);
        }
        this.carregando = false;
      });
    },
  },
  mounted() {
    this.getIP();
    this.getLocation();
    this.$validator.localize('en', locale);
    this.preencheForm();
  },
};
</script>

<style lang="scss" scoped>
.containericone {
  border: 1px solid #e7e7e7;
  width: 70%;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin: 0 auto;
}

.termos-necessarias {
  article {
    min-height: 150px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    h4 {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}

.hero::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid green;
}
.hero {
  .card-body {
    z-index: 2;
  }
}
</style>
