<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <div class="form">
        <b-form @submit.stop.prevent="onSubmit" class="text-left">
          <h4>Pré Abertura - {{ evento.Eventos_TipoEvento.descricao }}</h4>
          <hr />
          <b-list-group class="mb-3 perguntas">
            <b-list-group-item
              v-for="(item, index) in perguntasFilter"
              :key="item.id"
            >
              <b-row v-if="showOrDont(item)">
                <b-col>
                  <h6>{{ index + 1 }} - {{ item.pergunta }}</h6>
                </b-col>
              </b-row>
              <b-row v-if="item.respostasPossiveis">
                <b-col>
                  <b-select
                    :options="item.respostasPossiveis"
                    :name="`Resposta ${index + 1}`"
                    id="Tipo"
                    :multiple="item.ismultiplaEscolha"
                    v-model="item.resposta"
                    v-validate="{ required: true, isSelecione: true }"
                    :state="validateState(`Resposta ${index + 1}`)"
                    :class="validateClass(`Resposta ${index + 1}`)"
                    @input="validaResposta"
                  ></b-select>
                </b-col>
              </b-row>
              <b-row v-if="item.isAberta">
                <b-col>
                  <b-form-textarea
                    id="textarea"
                    v-model="item.resposta"
                    rows="3"
                    max-rows="6"
                    :name="`Resposta ${index + 1}`"
                    v-validate="{ required: true }"
                    :state="validateState(`Resposta ${index + 1}`)"
                    :class="validateClass(`Resposta ${index + 1}`)"
                  ></b-form-textarea>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <b-button
            pill
            ref="btnAvancar"
            size="lg"
            type="submit"
            variant="info"
            class="btn btn-block mb-5"
            :style="`background-color:${temaCor.botaoFormularios};`"
            >Salvar</b-button
          >
        </b-form>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import service from '../../services';
import moment from 'moment';
import locale from './../../locale';
import { Validator } from 'vee-validate';

Validator.extend('isSelecione', {
  getMessage: (field) =>
    'Necessário escolher um opçao para o campo ' + field + '.',
  validate(value, args) {
    if (value == 'Selecione') return false;
    else return true;
  },
});

export default {
  name: 'DashBoard',
  props: ['evento', 'temaCor'],
  watch: {
    evento(value) {
      // console.log("Chamou o preencher");
      //this.preencheForm();
    },
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      carregando: false,
      idEvento: this.$route.params.idEvento,
      perguntas: [],
      perguntasFilter: [],
      form: {
        teste: null,
      },
    };
  },
  components: {},
  methods: {
    validaResposta() {
      var aux = [];
      this.perguntas.forEach((element) => {
        element.show = this.showOrDont(element);
        aux.push(element);
      });
      this.perguntas = aux;
      this.filtraPerguntas();
    },
    buscaPerguntas() {
      this.carregando = true;
      console.log('Busca Perguntas', this.idEvento);
      service
        .post('Eventos', 'BuscarPerguntas', {
          idEvento: this.idEvento,
          idEnvolvido: null,
          epico: 'Pré-Abertura',
        })
        .then((res) => {
          this.carregando = false;
          res.data.forEach((element) => {
            if (element.respostasPossiveis) {
              var aux = element.respostasPossiveis.split(',');
              aux.unshift('Selecione');
              element.respostasPossiveis = aux;
            }
            if (element.resposta && element.ismultiplaEscolha) {
              element.resposta = element.resposta.split(',');
            }

            element.show = this.showOrDont(element);
            this.perguntas.push(element);
          });
          console.log(this.perguntas);
          this.filtraPerguntas();
        })
        .catch((e) => {
          this.carregando = false;
        });
    },
    filtraPerguntas() {
      console.log(this.form.idMotivo, this.perguntas);
      this.perguntasFilter = this.perguntas.filter((x) => x.show == true);
      this.perguntasFilter.sort(function (a, b) {
        return a.ordenacao < b.ordenacao
          ? -1
          : a.ordenacao > b.ordenacao
          ? 1
          : 0;
      });
    },
    showOrDont(item) {
      //console.log(item);
      if (item.idPerguntaDependente != null) {
        var aux = this.perguntas.find(
          (x) => x.idPergunta == item.idPerguntaDependente,
        );
        if (!aux) return false;
        if (aux.resposta == item.respostaDependente) return true;
        else return false;
      } else return true;
    },
    preencheForm() {
      if (this.evento) {
        console.log(this.evento);
        this.buscaPerguntas();
      } else this.preencheForm();
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.form.respostas = this.perguntas;
        this.$emit('send', this.form);
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
  },
  mounted() {
    this.preencheForm();
    this.$validator.localize('en', locale);
  },
};
</script>

<style lang="scss" scoped>
.perguntas h6 {
  font-weight: bold;
  font-size: 13px;
}
</style>
