<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <div class="form">
        <h4 class="my-2 mb-4">Envolvidos</h4>
        <div class="btn-add text-right">
          <b-button
            pill
            ref="btnAvancar"
            type="button"
            variant="outline-dark"
            class="btn mb-2"
            @click="openModalInsert('Envolvido')"
          >
            <i class="fa fa-user mr-2"></i>+ Adicionar Envolvido</b-button
          >
        </div>
        <b-list-group class="mb-3">
          <b-list-group-item variant="danger" v-if="envolvidos.length == 0">
            <div>Nenhum Envolvido Adicionado</div>
          </b-list-group-item>
          <b-list-group-item v-for="item in envolvidos" :key="item.id">
            <b-row>
              <b-col cols="7">
                Nome :
                {{ item.nome }}<br />
                Celular: {{ item.celular }}<br />
              </b-col>
              <b-col class="text-right">
                <a
                  href="javascript:void(0)"
                  @click="editaDados(item, 'Envolvido')"
                >
                  <i class="fa fa-edit mr-2"></i>
                  <span>Editar Dados</span> </a
                ><br />
                <a
                  href="javascript:void(0)"
                  @click="
                    itemEnvolvido = item;
                    modalLink = true;
                  "
                >
                  <i class="fa fa-link mr-2"></i>
                  <span>Enviar Link</span>
                </a>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>

        <hr />
        <h4 class="my-2 mb-4">Testemunhas</h4>
        <div class="btn-add text-right">
          <b-button
            pill
            ref="btnAvancar"
            type="button"
            variant="outline-dark"
            class="btn mb-2"
            @click="openModalInsert('Testemunha')"
          >
            <i class="fa fa-user mr-2"></i>+ Adicionar Testemunha</b-button
          >
        </div>
        <b-list-group class="mb-3">
          <b-list-group-item variant="danger" v-if="testemunhas.length == 0">
            <div>Nenhum Testemunha Adicionada</div>
          </b-list-group-item>
          <b-list-group-item v-for="item in testemunhas" :key="item.id">
            <b-row>
              <b-col cols="7">
                Nome :
                {{ item.nome }}<br />
                Celular: {{ item.celular }}<br />
              </b-col>
              <b-col class="text-right">
                <a
                  href="javascript:void(0)"
                  @click="editaDados(item, 'Testemunha')"
                >
                  <i class="fa fa-edit mr-2"></i>
                  <span>Editar Dados</span>
                </a>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>

        <b-button
          pill
          ref="btnAvancar"
          size="lg"
          type="submit"
          variant="info"
          class="btn btn-block mb-5"
          :style="`background-color:${temaCor.botaoFormularios};`"
          @click="avancarPasso()"
          >Avançar</b-button
        >
      </div>
    </div>
    <b-modal
      v-model="modalLink"
      hide-footer
      centered
      :title="`Enviar Link para Concluir Cadastro`"
    >
      <div class="container">
        <b-row>
          <b-col>
            <div class="alert alert-warning">
              Atençao, é necessário que o Envolvido termine de informar alguns
              dados e envie as documentações necessárias
            </div>
          </b-col>
        </b-row>
        <b-row v-if="itemEnvolvido">
          <b-col v-if="itemEnvolvido.email" class="text-center text-secondary">
            <a :href="returnLinkEmail(itemEnvolvido)">
              <font-awesome-icon icon="envelope" class="mt-3" />
              <p>Enviar por Email</p>
            </a>
          </b-col>
          <b-col class="text-center bg-success text-secondary">
            <a
              target="_blank"
              class="text-white"
              :href="returnLinkWhats(itemEnvolvido)"
            >
              <i class="fa fa-whatsapp mt-3"></i>
              <p>
                <b>Enviar por Whatsapp</b>
              </p>
            </a>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      v-model="modalEnvolvido"
      hide-footer
      centered
      :title="`${
        form.idEnvolvido
          ? 'Atualizar dados ' + form.tipo
          : 'Adicionar ' + form.tipo
      }`"
    >
      <b-form @submit.stop.prevent="onSubmitEnvolvido" class="text-left">
        <b-form-group
          id="input-group-2"
          label="Tipo de Colisão"
          label-for="input-2"
          v-if="form.tipo == 'Envolvido'"
        >
          <b-select
            :options="tiposVeiculos"
            name="Tipo de Veiculo"
            id="Tipo"
            v-model="form.codTipoVeiculo"
            v-validate="{ required: true }"
            :state="validateState('Tipo de Veiculo')"
            :class="validateClass('Tipo de Veiculo')"
          ></b-select>
        </b-form-group>

        <b-form-group label="Nome" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.nome"
            placeholder="Nome"
            name="Nome"
            v-validate="{ required: true }"
            :state="validateState('Nome')"
            :class="validateClass('Nome')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Telefone" id="input-group-2" label-for="input-2">
          <the-mask
            id="input-2"
            class="form-control"
            v-model="form.telefone"
            name="Telefone"
            placeholder="Telefone"
            :masked="true"
            mask="(##) ####-####"
            :state="validateState('Telefone')"
            :class="validateClass('Telefone')"
          ></the-mask>
        </b-form-group>

        <b-form-group id="input-group-2" label="Celular" label-for="input-2">
          <the-mask
            id="input-2"
            class="form-control"
            v-model="form.celular"
            name="Celular"
            placeholder="Celular"
            :masked="true"
            mask="(##) #####-####"
            v-validate="{ required: true }"
            :state="validateState('Celular')"
            :class="validateClass('Celular')"
          ></the-mask>
        </b-form-group>
        <b-form-group label="Email" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.email"
            placeholder="Email"
            name="Email"
            v-validate="{ email: true }"
            :state="validateState('Email')"
            :class="validateClass('Email')"
          ></b-form-input>
        </b-form-group>
        <b-button
          pill
          ref="btnAvancar"
          size="lg"
          type="submit"
          variant="info"
          class="btn btn-block mb-5"
          :style="`background-color:${temaCor.botaoFormularios};`"
          >{{ form.idEnvolvido ? 'Atualizar' : 'Adicionar ' }}</b-button
        >
      </b-form>
    </b-modal>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import service from '../../services';
import moment from 'moment';
import locale from '../../locale';
export default {
  name: 'DashBoard',
  props: ['evento', 'temaCor'],
  watch: {
    evento(value) {
      // console.log("Chamou o preencher");
      this.preencheForm();
    },
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      carregando: false,
      modalEnvolvido: false,
      modalLink: false,
      envolvidoSelecionado: null,
      itemEnvolvido: {},
      envolvidos: [],
      testemunhas: [],
      tiposVeiculos: [
        { text: 'Selecione', value: null },
        { text: 'Carro', value: 1 },
        { text: 'Moto', value: 2 },
        { text: 'Caminhão', value: 3 },
      ],
      form: {
        tipo: null,
        nome: null,
        email: null,
        telefone: null,
        celular: null,
        codTipoVeiculo: null,
      },
    };
  },
  components: {},
  methods: {
    returnLinkEmail(item) {
      var link = '';
      if (item.email) {
        var nome = item.nome;
        var chave = item.chave;
        var email = item.email;
        var texto = `Olá ${nome}, tudo bem? Acesse ${window.location.origin.replace(
          'app',
          'adm',
        )}/complementarEvento/${chave} para completar seu cadastro como envolvido no Evento.`;
        var textoEncode = encodeURI(texto);
        link = `mailto:${email}?subject=Cadastro de Envolvido&body=${textoEncode}`;
      }
      return link;
    },
    returnLinkWhats(item) {
      var link = '';
      if (item.nome) {
        var nome = item.nome;
        var celular = item.celular
          ? item.celular
          : item.telefone
              .replace('(', '')
              .replace(')', '')
              .replace(' ', '')
              .replace('-', '');
        var texto = `Olá ${nome}, tudo bem? Acesse ${window.location.origin.replace(
          'app',
          'adm',
        )}/complementarEvento/${
          item.chave
        } para completar seu cadastro como envolvido no Evento.`;
        var textoEncode = encodeURI(texto);

        link = `https://api.whatsapp.com/send?phone=55${celular}&text=${textoEncode}`;
      }

      return link;
    },
    avancarPasso() {
      this.$router.push(`/editarEvento/${this.evento.idEvento}/5`);
    },
    preencheForm() {
      if (!this.evento.Eventos_TipoEvento.solicitaEnvolvidos) {
        this.$router.push(`/editarEvento/${this.evento.idEvento}/5`);
      }
      if (this.evento) {
        this.buscaEnvolvidos();
        this.buscaTestemunhas();
      } else this.preencheForm();
    },
    openModalInsert(tipo) {
      this.form.idEnvolvido = null;
      this.form.tipo = tipo;
      this.form.idTestemunha = null;
      this.form.nome = null;
      this.form.email = null;
      this.form.celular = null;
      this.form.telefone = null;
      this.form.codTipoVeiculo = null;
      this.modalEnvolvido = true;
    },
    editaDados(item, tipo) {
      this.form.tipo = tipo;
      this.form.idEnvolvido = item.idEnvolvido;
      this.form.idTestemunha = item.idTestemunha;
      this.form.nome = item.nome;
      this.form.email = item.email;
      this.form.celular = item.celular;
      this.form.telefone = item.telefone;
      this.form.codTipoVeiculo = item.codTipoVeiculo;
      this.modalEnvolvido = true;
    },
    buscaEnvolvidos() {
      this.carregando = true;
      console.log('Busca Envonlvidos', this.evento.idEvento);
      service
        .post('Eventos', 'listaEnvolvidosPorEvento', {
          idEvento: this.evento.idEvento,
        })
        .then((res) => {
          this.carregando = false;
          this.envolvidos = res.data;
        })
        .catch((e) => {
          this.carregando = false;
        });
    },
    buscaTestemunhas() {
      this.carregando = true;
      console.log('Busca Testemunhas', this.evento.idEvento);
      service
        .post('Eventos', 'listaTestemunhasPorEvento', {
          idEvento: this.evento.idEvento,
          idEnvolvido: null,
        })
        .then((res) => {
          this.carregando = false;
          this.testemunhas = res.data;
        })
        .catch((e) => {
          this.carregando = false;
        });
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmitEnvolvido() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.carregando = true;
        this.form.idEvento = this.evento.idEvento;
        var action = 'add-atualiza' + this.form.tipo;

        service
          .post('Eventos', action, this.form)
          .then((res) => {
            this.carregando = false;
            this.modalEnvolvido = false;
            this.$bvToast.toast(
              `Dados ${
                this.form.idEnvolvido ? 'Atualizados' : 'Adicionados'
              } com sucesso`,
              {
                title: 'Atenção!',
                solid: true,
                variant: 'success',
                toaster: 'b-toaster-top-full',
                appendToast: false,
                autoHideDelay: 1500,
              },
            );
            if (this.form.tipo == 'Envolvido') this.buscaEnvolvidos();
            else this.buscaTestemunhas();
          })
          .catch((e) => {
            this.carregando = false;
          });

        console.log(this.form);
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
    this.preencheForm();
  },
};
</script>

<style lang="scss" scoped>
.btn-add {
  .btn {
    border: 1px solid #343a40;
  }
}
</style>
