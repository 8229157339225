<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <div class="form" v-if="evento">
        <b-card
          title="Dados do Condutor"
          v-if="exibeForm == false"
          tag="article"
          class="mb-2"
        >
          <b-row>
            <b-col cols="7">
              Nome :
              {{ evento.Eventos_Associado.nome }}<br />
              Cpf: {{ evento.Eventos_Associado.cpfcnpj }}<br />
              Celular: {{ evento.Eventos_Associado.celular }}<br />
            </b-col>
            <b-col class="text-right">
              <a href="javascript:void(0)" @click="exibeForm = true">
                <i class="fa fa-edit mr-2"></i>
                <span>Editar Dados</span>
              </a>
            </b-col>
          </b-row>
        </b-card>
        <b-form @submit.stop.prevent="onSubmit" class="text-left">
          <div
            id="FormCondutor"
            :style="`${exibeForm == true ? 'display:block' : 'display:none'}`"
          >
            <h4 class="text-center mb-3"></h4>
            <b-card title="Dados do Condutor" tag="article" class="mb-2">
              <b-card-text>
                <b-form-group id="input-group-4" v-if="exibeCheck">
                  <b-form-checkbox
                    size="lg"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="form.utilizarDadosAssociado"
                    @input="verificaDadosAssociado()"
                    switch
                    >Utilizar Dados de Associado</b-form-checkbox
                  >
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Nome"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.nome"
                    placeholder="Nome"
                    name="Nome"
                    v-validate="{ required: true }"
                    :state="validateState('Nome')"
                    :class="validateClass('Nome')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="CPF" label-for="input-2">
                  <the-mask
                    type="text"
                    class="form-control"
                    v-model="form.cpf"
                    v-validate="{ required: true }"
                    placeholder="Cpf"
                    mask="###.###.###-##"
                    name="Cpf"
                    :masked="true"
                    :state="validateState('Cpf')"
                    :class="validateClass('Cpf')"
                  ></the-mask>
                </b-form-group>
                <b-form-group label="RG" label-for="input-2">
                  <b-form-input
                    type="number"
                    id="input-2"
                    v-model="form.rg"
                    placeholder="RG"
                    name="RG"
                    v-validate="{ required: true }"
                    :state="validateState('RG')"
                    :class="validateClass('RG')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="CNH" label-for="input-2">
                  <the-mask
                    type="text"
                    class="form-control"
                    v-model="form.cnh"
                    v-validate="{ required: true }"
                    placeholder="CNH"
                    mask="###########"
                    id="cnh"
                    name="CNH"
                    :masked="true"
                    :state="validateState('CNH')"
                    :class="validateClass('CNH')"
                  ></the-mask>
                </b-form-group>

                <b-form-group label="Data de Validade CHN" label-for="input-2">
                  <the-mask
                    type="text"
                    class="form-control"
                    v-model="form.dataVencimentoCnh"
                    v-validate="{ required: true, date_format: 'dd/MM/yyyy' }"
                    placeholder="Vencimento da CNH"
                    mask="##/##/####"
                    id="dataVencimentoCnh"
                    name="Vencimento da CNH"
                    :masked="true"
                    :state="validateState('Vencimento da CNH')"
                    :class="validateClass('Vencimento da CNH')"
                  ></the-mask>
                </b-form-group>
                <b-form-group
                  label="Telefone"
                  id="input-group-2"
                  label-for="input-2"
                >
                  <the-mask
                    id="input-2"
                    class="form-control"
                    v-model="form.telefone"
                    name="Telefone"
                    placeholder="Telefone"
                    :masked="true"
                    mask="(##) ####-####"
                    :state="validateState('Telefone')"
                    :class="validateClass('Telefone')"
                  ></the-mask>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Celular"
                  label-for="input-2"
                >
                  <the-mask
                    id="input-2"
                    class="form-control"
                    v-model="form.celular"
                    name="Celular"
                    placeholder="Celular"
                    :masked="true"
                    mask="(##) #####-####"
                    v-validate="{ required: true }"
                    :state="validateState('Celular')"
                    :class="validateClass('Celular')"
                  ></the-mask>
                </b-form-group>
              </b-card-text>
            </b-card>

            <b-card title="Endereço do Condutor" tag="article" class="mb-2">
              <b-card-text
                ><b-form-group>
                  <the-mask
                    type="text"
                    class="form-control"
                    v-model="cep"
                    v-validate="{ required: true, length: 10 }"
                    placeholder="CEP"
                    mask="##.###-###"
                    id="Cep"
                    name="Cep"
                    :masked="true"
                    :state="validateState('Cep')"
                    :class="validateClass('Cep')"
                  ></the-mask>
                </b-form-group>
                <b-form-group label="Endereço" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    v-model="form.endereco"
                    placeholder="Endereço"
                    name="Endereço"
                    v-validate="{ required: true }"
                    :state="validateState('Endereço')"
                    :class="validateClass('Endereço')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Número" label-for="input-2">
                  <b-form-input
                    type="number"
                    id="input-2"
                    v-model="form.numero"
                    placeholder="Número"
                    name="Número"
                    v-validate="{ required: true }"
                    :state="validateState('Número')"
                    :class="validateClass('Número')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Complemento" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    v-model="form.complemento"
                    placeholder="Complemento"
                    name="Complemento"
                    :state="validateState('Complemento')"
                    :class="validateClass('Complemento')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Bairro" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    v-model="form.bairro"
                    placeholder="Bairro"
                    name="Bairro"
                    v-validate="{ required: true }"
                    :state="validateState('Bairro')"
                    :class="validateClass('Bairro')"
                  ></b-form-input>
                </b-form-group>
                <div v-if="encontrouCep == false">
                  <b-form-group
                    id="input-group-2"
                    label="Estado"
                    label-for="input-2"
                  >
                    <b-select
                      :options="estados"
                      name="Estado"
                      id="Estado"
                      v-model="form.estado"
                      @change="buscaDadosSelectsCidades"
                      v-validate="{ required: true }"
                      :state="validateState('Estado')"
                      :class="validateClass('Estado')"
                    ></b-select>
                  </b-form-group>
                  <b-form-group
                    id="input-group-2"
                    label="Cidade"
                    label-for="input-2"
                  >
                    <b-select
                      :options="cidades"
                      name="Cidade"
                      id="Cidade"
                      v-model="form.cidade"
                      v-validate="{ required: true }"
                      :state="validateState('Cidade')"
                      :class="validateClass('Cidade')"
                    ></b-select>
                  </b-form-group>
                </div>
                <div v-if="encontrouCep == true">
                  <b-form-group
                    v-if="exibeCidade == true"
                    id="input-group-2"
                    label="Cidade"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="Cidade"
                      readonly
                      name="Cidade"
                      placeholder="Cidade"
                      v-model="form.cidade"
                      v-validate="{ required: true }"
                      :state="validateState('Cidade')"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="exibeCidade == true"
                    id="input-group-2"
                    label="Estado"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="Estado"
                      readonly
                      name="Estado"
                      placeholder="Estado"
                      v-model="form.estado"
                      v-validate="{ required: true }"
                      :state="validateState('Estado')"
                    ></b-form-input>
                  </b-form-group></div
              ></b-card-text>
            </b-card>
          </div>

          <div class="fotos-necessarias mt-4">
            <h5>Fotos / Documentação Necessárias</h5>
            <hr />
            <b-row>
              <b-col cols="6" v-for="item in fotos" :key="item.id">
                <b-card
                  :title="item.nomePosicao"
                  tag="article"
                  class="mb-3"
                  :class="`${item.nome ? 'hero' : ''}`"
                  :style="`${
                    item.nome ? `background-image:url('${item.nome}');` : ''
                  }`"
                >
                  <div
                    v-if="item.aprovada != true"
                    class="containericone"
                    @click="openModalFoto(item)"
                  >
                    <font-awesome-icon
                      class="icone mr-2"
                      :icon="['fa', 'camera']"
                    />
                    <font-awesome-icon class="icone" :icon="['fa', 'folder']" />
                  </div>
                  <b-badge v-if="item.aprovada == true" variant="success"
                    >Aprovada</b-badge
                  >
                  <b-badge
                    v-if="item.aprovada == false && item.motivoReprovacao"
                    variant="danger"
                    >Reprovada</b-badge
                  >
                </b-card>
              </b-col>
            </b-row>
          </div>
          <b-button
            pill
            ref="btnAvancar"
            size="lg"
            type="submit"
            variant="info"
            class="btn btn-block mb-5"
            :style="`background-color:${temaCor.botaoFormularios};`"
            >Avançar</b-button
          >
        </b-form>
        <b-modal
          v-model="modalFotosNaoPreenchidas"
          hide-footer
          centered
          :title="`Atenção`"
        >
          <div class="alert alert-warning legenda">
            Existem Fotos / Documentos necessários que ainda nao foram
            preenchidos, deseja continuar?
          </div>
          <center>
            <b-button
              pill
              ref="btnAvancar"
              size="lg"
              @click="continuarSemFoto()"
              variant="success"
              class="btn mb-5"
              >Sim, Continuar</b-button
            >
          </center>
        </b-modal>

        <b-modal
          v-model="modalFoto"
          hide-footer
          centered
          :title="`${fotoSelecionada.nomePosicao}`"
        >
          <componenteUpFoto
            :idEvento="evento.idEvento"
            :foto="fotoSelecionada"
            :temaCor="temaCor"
            @upload="preencheImagem"
          />
        </b-modal>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import service from '../../services';
import moment from 'moment';
import { Settings } from 'luxon';
Settings.defaultLocale = 'pt-br';
import locale from './../../locale';
import componenteUpFoto from './componente_upFoto';
import { decryptData } from '../../services/decryptData';

export default {
  name: 'DashBoard',
  props: ['evento', 'temaCor'],
  watch: {
    evento(value) {
      // console.log("Chamou o preencher");
      this.preencheForm();
    },
    cep: function (newValue, oldValue) {
      console.log(newValue, oldValue, this.cep);
      if (this.cep)
        if (this.cep.length == 10) {
          this.buscarCep();
        }
    },
  },
  components: { componenteUpFoto },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      carregando: false,
      cep: null,
      encontrouCep: null,
      mostraErroGeo: false,
      modalFoto: false,
      modalFotosNaoPreenchidas: false,
      maxDate: moment().format(),
      estados: [],
      cidades: [],
      fotos: [],
      fotoSelecionada: {},
      exibeCheck: true,
      exibeForm: true,
      form: {
        utilizarDadosAssociado: null,
        nome: null,
        cpf: null,
        rg: null,
        cnh: null,
        dataVencimentoCnh: null,
        telefone: null,
        celular: null,
        cep: null,
        endereco: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        estado: null,
        pais: null,
        cordenadas: null,
      },
    };
  },
  methods: {
    getIP() {
      fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then((response) => {
          console.log(response.ip);
          this.form.ipAceite = response.ip;
        });
    },
    getLocation() {
      if (navigator.geolocation) {
        console.log('getLocation was called', navigator.geolocation);
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.positionError,
        );
      }
    },
    showPosition(position) {
      this.form.cordenadas = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        altitude: null,
        altitudeAccuracy: null,
        accuracy: 43,
      };
      console.log(
        'Latitude: ' +
          position.coords.latitude +
          '<br>Longitude: ' +
          position.coords.longitude,
      );
    },
    positionError() {
      console.log('erro');
      this.mostraErroGeo = true;
    },
    preencheImagem(obj) {
      this.modalFoto = false;
      console.log(obj, this.evento.idEvento);
      this.fotos.forEach((element) => {
        console.log(element.id_modeloFoto, obj.foto.id_modeloFoto);
        if (element.id_modeloFoto == obj.foto.id_modeloFoto) {
          element.nome = obj.url;
          element.motivoReprovacao = '';
          element.aprovada = null;
          element.atualizada = true;
          element.coordenadas = obj.coordenadas;
          console.log(element.nome);
        }
      });
      console.log(this.fotos);
    },
    openModalFoto(item) {
      this.fotoSelecionada = item;
      this.modalFoto = true;
    },
    verificaDadosAssociado() {
      if (this.form.utilizarDadosAssociado == true) {
        this.form.nome = this.evento.Eventos_Associado.nome;
        this.form.cpf = this.evento.Eventos_Associado.cpfcnpj;
        this.form.rg = this.evento.Eventos_Associado.rgIe;
        this.form.cnh = this.evento.Eventos_Associado.cnh;
        if (this.evento.Eventos_Associado.dataVencimentoCnh)
          this.form.dataVencimentoCnh = moment(
            this.evento.Eventos_Associado.dataVencimentoCnh,
          )
            .utc()
            .format('DD/MM/YYYY');
        this.form.telefone = this.evento.Eventos_Associado.telefone;
        this.form.celular = this.evento.Eventos_Associado.celular;
        this.form.cep = this.evento.Eventos_Associado.cep;
        this.cep = this.evento.Eventos_Associado.cep;
        this.form.endereco = this.evento.Eventos_Associado.endereco;
        this.form.numero = this.evento.Eventos_Associado.numero;
        this.form.complemento = this.evento.Eventos_Associado.complemento;
        this.form.bairro = this.evento.Eventos_Associado.bairro;
        this.form.cidade = this.evento.Eventos_Associado.cidade;
        this.form.estado = this.evento.Eventos_Associado.estado;
        this.form.pais = this.evento.Eventos_Associado.pais;
      } else {
        this.form.nome = null;
        this.form.cpf = '';
        this.form.rg = null;
        this.form.cnh = null;
        this.form.dataVencimentoCnh = '';
        this.form.telefone = '';
        this.form.celular = '';
        this.form.cep = '';
        this.cep = '';
        this.form.endereco = null;
        this.form.numero = null;
        this.form.complemento = null;
        this.form.bairro = null;
        this.form.cidade = null;
        this.form.estado = null;
        this.form.pais = null;
        console.log(this.form);
      }
    },
    buscarCep() {
      if (this.cep.length == 10) {
        this.form.cep = this.cep;
        this.carregando = true;
        var cepbusca = this.form.cep.replace('.', '').replace('-', '');
        //console.log(dadosFrete);
        service
          .post('Cep', 'BuscaCEP', {
            cep: cepbusca,
          })
          .then((res) => {
            this.encontrouCep = true;
            this.exibeCidade = true;
            console.log(res);
            const parsed = JSON.stringify(res.data);
            this.form.endereco = res.data.street;
            this.form.bairro = res.data.neighborhood;
            this.form.cidade = res.data.city;
            this.form.estado = res.data.state;
            this.form.pais = 'BR';
            this.$refs.btnAvancar.focus();
            this.carregando = false;
          })
          .catch((e) => {
            this.encontrouCep = false;
            this.exibeCidade = false;
            this.carregando = false;
            this.form.endereco = null;
            this.form.bairro = null;
            this.form.cidade = null;
            this.form.estado = null;
            this.form.pais = null;
            this.$bvToast.toast('Não foi possível buscar o CEP', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      }
    },
    preencheForm() {
      if (!this.evento.Eventos_TipoEvento.solicitaCondutor) {
        this.$router.push(`/editarEvento/${this.evento.idEvento}/4`);
      }

      this.buscaFotosToUpload();
      if (this.evento.dadosCondutor) {
        this.exibeCheck = false;
        var dadosCondutor = JSON.parse(this.evento.dadosCondutor);
        this.form.utilizarDadosAssociado = dadosCondutor.utilizarDadosAssociado;
        this.form.nome = dadosCondutor.nome;
        this.form.cpf = dadosCondutor.cpf;
        this.form.rg = dadosCondutor.rg;
        this.form.cnh = dadosCondutor.cnh;
        this.form.dataVencimentoCnh = dadosCondutor.dataVencimentoCnh;
        this.form.telefone = dadosCondutor.telefone;
        this.form.celular = dadosCondutor.celular;

        this.cep = dadosCondutor.cep;
        this.form.endereco = dadosCondutor.endereco;
        this.form.numero = dadosCondutor.numero;
        this.form.complemento = dadosCondutor.complemento;
        this.form.bairro = dadosCondutor.bairro;
        this.form.cidade = dadosCondutor.cidade;
        this.form.estado = dadosCondutor.estado;
        this.form.pais = dadosCondutor.pais;
        this.exibeForm = false;
      } else {
        this.exibeForm = true;
      }
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.form.fotos = this.fotos.filter(
          (x) => x.nome != null && x.atualizada == true,
        );
        this.form.idPessoa = this.evento.Eventos_Associado.idProspect;
        var fotosSemPreenchimento = this.fotos.filter(
          (x) => x.nome == null || (x.aprovada == 0 && !x.atualizada),
        );

        if (fotosSemPreenchimento.length > 0) {
          this.modalFotosNaoPreenchidas = true;
          return;
        }
        this.$emit('send', this.form);
      });
    },
    continuarSemFoto() {
      this.modalFotosNaoPreenchidas = false;
      this.$emit('send', this.form);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    buscaDadosSelects(model, array, campoDescricao, campoPK) {
      this.carregando = true;
      var url = `${model}/list/idEmpresa/${this.form.idEmpresa}`;

      if (model == 'domUf') url = `${model}/list`;
      service.getAll('g', {}, url).then((res) => {
        res.data = decryptData(res.data);
        this[array].push({ text: 'Selecione', value: null });
        res.data.forEach((element) => {
          this[array].push({
            value: { id: element[campoPK], valor: element[campoDescricao] },
            text: element[campoDescricao],
          });
        });
        console.log(this[array]);
        this.carregando = false;
      });
    },
    buscaDadosSelectsCidades() {
      this.carregando = true;
      this.cidades = [];
      service
        .getAll('g', {}, `domMunicipio/list/codUf/${this.form.estado.id}`)
        .then((res) => {
          res.data = decryptData(res.data);
          res.data.forEach((element) => {
            this.cidades.push({
              value: element.nomeCidade,
              text: element.nomeCidade,
            });
          });

          this.carregando = false;
        });
    },
    buscaFotosToUpload() {
      this.carregando = true;
      this.fotos = [];
      var obj = {};
      obj.idEvento = this.evento.idEvento;
      obj.tipoPessoa = 'Condutor';
      obj.idPessoa = this.evento.Eventos_Associado.idProspect;

      service.post('Eventos', 'buscaFotosToUpload', obj).then((res) => {
        console.log(res);
        this.fotos = res.data;
        this.carregando = false;
      });
    },
  },
  mounted() {
    this.getIP();
    this.getLocation();
    this.$validator.localize('en', locale);
    this.buscaDadosSelects('domUf', 'estados', 'nomeUf', 'codUf');
    this.preencheForm();
  },
};
</script>

<style lang="scss" scoped>
.containericone {
  border: 1px solid #e7e7e7;
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin: 0 auto;
}

.fotos-necessarias {
  article {
    min-height: 150px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    h4 {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}

.hero::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid green;
}
.hero {
  .card-body {
    z-index: 2;
  }
}
</style>
