<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <div class="form" v-if="evento">
        <b-form @submit.stop.prevent="onSubmit" class="text-left">
          <h4 class="text-center mb-3">
            {{ evento.Eventos_TipoEvento.descricao }}
          </h4>
          <b-card title="Local do Evento" tag="article" class="mb-2">
            <b-card-text
              ><b-form-group>
                <the-mask
                  type="text"
                  class="form-control"
                  v-model="cep"
                  v-validate="{ required: true, length: 10 }"
                  placeholder="CEP"
                  mask="##.###-###"
                  id="Cep"
                  name="Cep"
                  :masked="true"
                  :state="validateState('Cep')"
                  :class="validateClass('Cep')"
                ></the-mask>
              </b-form-group>
              <b-form-group label="Endereço" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.endereco"
                  placeholder="Endereço"
                  name="Endereço"
                  v-validate="{ required: true }"
                  :state="validateState('Endereço')"
                  :class="validateClass('Endereço')"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Número" label-for="input-2">
                <b-form-input
                  type="number"
                  id="input-2"
                  v-model="form.numero"
                  placeholder="Número"
                  name="Número"
                  v-validate="{ required: true }"
                  :state="validateState('Número')"
                  :class="validateClass('Número')"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Complemento" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.complemento"
                  placeholder="Complemento"
                  name="Complemento"
                  :state="validateState('Complemento')"
                  :class="validateClass('Complemento')"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Bairro" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.bairro"
                  placeholder="Bairro"
                  name="Bairro"
                  v-validate="{ required: true }"
                  :state="validateState('Bairro')"
                  :class="validateClass('Bairro')"
                ></b-form-input>
              </b-form-group>
              <div v-if="encontrouCep == false">
                <b-form-group
                  id="input-group-2"
                  label="Estado"
                  label-for="input-2"
                >
                  <b-select
                    :options="estados"
                    name="Estado"
                    id="Estado"
                    v-model="form.estado"
                    @change="buscaDadosSelectsCidades"
                    v-validate="{ required: true }"
                    :state="validateState('Estado')"
                    :class="validateClass('Estado')"
                  ></b-select>
                </b-form-group>
                <b-form-group
                  id="input-group-2"
                  label="Cidade"
                  label-for="input-2"
                >
                  <b-select
                    :options="cidades"
                    name="Cidade"
                    id="Cidade"
                    v-model="form.cidade"
                    v-validate="{ required: true }"
                    :state="validateState('Cidade')"
                    :class="validateClass('Cidade')"
                  ></b-select>
                </b-form-group>
              </div>
              <div v-if="encontrouCep == true">
                <b-form-group
                  v-if="exibeCidade == true"
                  id="input-group-2"
                  label="Cidade"
                  label-for="input-2"
                >
                  <b-form-input
                    id="Cidade"
                    readonly
                    name="Cidade"
                    placeholder="Cidade"
                    v-model="form.cidade"
                    v-validate="{ required: true }"
                    :state="validateState('Cidade')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="exibeCidade == true"
                  id="input-group-2"
                  label="Estado"
                  label-for="input-2"
                >
                  <b-form-input
                    id="Estado"
                    readonly
                    name="Estado"
                    placeholder="Estado"
                    v-model="form.estado"
                    v-validate="{ required: true }"
                    :state="validateState('Estado')"
                  ></b-form-input>
                </b-form-group></div
            ></b-card-text>
          </b-card>
          <b-button
            pill
            ref="btnAvancar"
            size="lg"
            type="submit"
            variant="info"
            class="btn btn-block mb-5"
            :style="`background-color:${temaCor.botaoFormularios};`"
            >Avançar</b-button
          >
        </b-form>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import service from '../../services';
import moment from 'moment';
import { Settings } from 'luxon';
Settings.defaultLocale = 'pt-br';
import locale from './../../locale';
import { decryptData } from '../../services/decryptData';

export default {
  name: 'DashBoard',
  props: ['evento', 'temaCor'],
  watch: {
    evento(value) {
      // console.log("Chamou o preencher");
      this.preencheForm();
    },
    cep: function (newValue, oldValue) {
      console.log(newValue, oldValue, this.cep);
      if (this.cep)
        if (this.cep.length == 10) {
          this.buscarCep();
        }
    },
  },

  data() {
    return {
      decoded: {},
      menuOpen: false,
      carregando: false,
      cep: null,
      encontrouCep: null,
      maxDate: moment().format(),
      estados: [],
      cidades: [],
      form: {
        endereco: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        estado: null,
        pais: null,
      },
    };
  },
  components: {},
  methods: {
    buscarCep() {
      if (this.cep.length == 10) {
        this.form.cep = this.cep;
        this.carregando = true;
        var cepbusca = this.form.cep.replace('.', '').replace('-', '');
        //console.log(dadosFrete);
        service
          .post('Cep', 'BuscaCEP', {
            cep: cepbusca,
          })
          .then((res) => {
            this.encontrouCep = true;
            this.exibeCidade = true;
            console.log(res);
            const parsed = JSON.stringify(res.data);
            this.form.endereco = res.data.street;
            this.form.bairro = res.data.neighborhood;
            this.form.cidade = res.data.city;
            this.form.estado = res.data.state;
            this.form.pais = 'BR';
            this.$refs.btnAvancar.focus();
            this.carregando = false;
          })
          .catch((e) => {
            this.encontrouCep = false;
            this.exibeCidade = false;
            this.carregando = false;
            this.form.endereco = null;
            this.form.bairro = null;
            this.form.cidade = null;
            this.form.estado = null;
            this.form.pais = null;
            this.$bvToast.toast('Não foi possível buscar o CEP', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      }
    },
    preencheForm() {
      if (this.evento.enderecoEvento) {
        var dadosEndereco = JSON.parse(this.evento.enderecoEvento);
        this.cep = dadosEndereco.cep;
        this.form.endereco = dadosEndereco.endereco;
        this.form.numero = dadosEndereco.numero;
        this.form.complemento = dadosEndereco.complemento;
        this.form.bairro = dadosEndereco.bairro;
        this.form.cidade = dadosEndereco.cidade;
        this.form.estado = dadosEndereco.estado;
        this.form.pais = dadosEndereco.pais;
      }
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.$emit('send', this.form);
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    buscaDadosSelects(model, array, campoDescricao, campoPK) {
      this.carregando = true;
      var url = `${model}/list/idEmpresa/${this.form.idEmpresa}`;

      if (model == 'domUf') url = `${model}/list`;
      service.getAll('g', {}, url).then((res) => {
        res.data = decryptData(res.data);
        this[array].push({ text: 'Selecione', value: null });
        res.data.forEach((element) => {
          this[array].push({
            value: { id: element[campoPK], valor: element[campoDescricao] },
            text: element[campoDescricao],
          });
        });
        console.log(this[array]);
        this.carregando = false;
      });
    },
    buscaDadosSelectsCidades() {
      this.carregando = true;
      this.cidades = [];
      service
        .getAll('g', {}, `domMunicipio/list/codUf/${this.form.estado.id}`)
        .then((res) => {
          res.data = decryptData(res.data);
          res.data.forEach((element) => {
            this.cidades.push({
              value: element.nomeCidade,
              text: element.nomeCidade,
            });
          });

          this.carregando = false;
        });
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
    this.buscaDadosSelects('domUf', 'estados', 'nomeUf', 'codUf');
  },
};
</script>

<style lang="scss" scoped></style>
