<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <div class="form">
        <h4 class="my-2 mb-4">Croqui</h4>
        <div class="alert alert-info">
          Arraste os veículos afim de mostrar como ocasionou o ocorrido.
        </div>
        <b-form @submit.stop.prevent="onSubmit" class="text-left">
          <v-stage
            v-if="possuiImagem == false || isEdit == true"
            ref="stage"
            :config="stageSize"
            @mousedown="handleStageMouseDown"
            @touchstart="handleStageMouseDown"
          >
            <v-layer v-if="image">
              <v-image :config="imgFundo" />
            </v-layer>
            <v-layer v-if="image" ref="layer">
              <v-image
                v-for="item in cars"
                :key="item.id"
                @dragstart="handleDragStart"
                @dragend="handleDragEnd"
                @transformend="handleTransformEnd"
                :config="item"
              />
              <v-transformer ref="transformer" />
            </v-layer>
          </v-stage>
          <div v-else>
            <a href="javascript:void(0)" @click="isEdit = true">
              <i class="fa fa-edit mr-2"></i>
              <span>Editar Croqui</span>
            </a>
            <img :src="imagemExistente" />
          </div>
          <h5 class="mt-3">Legenda</h5>
          <b-list-group class="mb-3">
            <b-list-group-item v-for="item in cars" :key="item.id">
              <b-row>
                <b-col cols="2">
                  <img width="20" height="20" :src="item.imgAux" />
                </b-col>
                <b-col>
                  {{ item.text }}
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <b-button
            v-if="possuiImagem == false || isEdit == true"
            pill
            ref="btnAvancar"
            size="lg"
            type="submit"
            variant="info"
            class="btn btn-block mb-5"
            :style="`background-color:${temaCor.botaoFormularios};`"
            >Salvar</b-button
          >
          <b-button
            v-else
            pill
            ref="btnAvancar"
            size="lg"
            type="button"
            @click="avancarPasso()"
            variant="info"
            class="btn btn-block mb-5"
            :style="`background-color:${temaCor.botaoFormularios};`"
            >Avançar</b-button
          >
        </b-form>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import service from '../../services';
import moment from 'moment';
import locale from './../../locale';
import canvasToBlob from 'blueimp-canvas-to-blob';

const width = window.innerWidth;
const height = window.innerHeight;

export default {
  name: 'DashBoard',
  props: ['evento', 'temaCor'],
  watch: {
    evento(value) {
      // console.log("Chamou o preencher");
      this.preencheForm();
    },
  },
  data() {
    return {
      render: false,
      possuiImagem: false,
      imagemExistente: null,
      isEdit: false,
      stageSize: {
        id: 'canvas-container',
        width: width - 30,
        height: 280,
      },
      imgFundo: {
        x: 0,
        y: 0,
        image: null,
        width: width - 30,
        height: 280,
      },
      selectedShapeName: '',
      cars: [],
      envolvidos: [],
      files: new FormData(),
      isDragging: false,
      image: null,
      decoded: {},
      menuOpen: false,
      carregando: false,
      form: {
        croqui: null,
      },
    };
  },
  components: {},
  methods: {
    handleTransformEnd(e) {
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      const rect = this.cars.find((r) => r.name === this.selectedShapeName);
      // update the state
      rect.x = e.target.x();
      rect.y = e.target.y();
      rect.rotation = e.target.rotation();
      rect.scaleX = e.target.scaleX();
      rect.scaleY = e.target.scaleY();
    },
    handleStageMouseDown(e) {
      // clicked on stage - clear selection
      console.log(e);
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = '';
        this.updateTransformer();
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }

      // find clicked rect by its name
      const name = e.target.name();
      const rect = this.cars.find((r) => r.name === name);
      if (rect) {
        this.selectedShapeName = name;
      } else {
        this.selectedShapeName = '';
      }
      this.updateTransformer();
    },
    updateTransformer() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const { selectedShapeName } = this;

      const selectedNode = stage.findOne('.' + selectedShapeName);
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
      transformerNode.getLayer().batchDraw();
    },
    handleDragStart() {
      this.isDragging = true;
    },
    handleDragEnd() {
      this.isDragging = false;
    },

    buscaEnvolvidos() {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        service
          .post('Eventos', 'listaEnvolvidosPorEvento', {
            idEvento: this.evento.idEvento,
          })
          .then((res) => {
            this.carregando = false;
            this.envolvidos = res.data;
            resolve();
          })
          .catch((e) => {
            this.carregando = false;
            reject();
          });
      });
    },

    async preencheForm() {
      if (this.evento) {
        this.form.croqui = this.evento.croqui;
        if (this.evento.croqui) {
          this.possuiImagem = true;
          this.imagemExistente = this.evento.croqui;
        }
        await this.buscaEnvolvidos();
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.src = `https://bboneapp.s3.amazonaws.com/imgsPadrao/imgEventos/bgrua.png`;

        image.onload = () => {
          if (this.render == false) {
            this.render = true;
            //image.setAttribute("crossorigin", "anonymous");

            this.image = image;
            this.imgFundo.image = image;
            var cont = {
              1: 1,
              2: 1,
              3: 1,
            };
            this.cars = [];
            console.log(this.envolvidos, this.evento.Eventos_Veiculo);
            this.cars.push({
              rotation: 0,
              x: 50 * (this.envolvidos.length + 1),
              y: 50 * (this.envolvidos.length + 1),
              scaleX: 1,
              scaleY: 1,
              name: 'car0',
              image: null,
              draggable: true,
              text: 'Seu Veículo',
              tipoVeiculo: this.evento.Eventos_Veiculo.codTipoVeiculo,
            });
            this.envolvidos.forEach((element, index) => {
              this.cars.push({
                rotation: 0,
                x: 50 * (index + 1),
                y: 50 * (index + 1),
                scaleX: 1,
                scaleY: 1,
                name: 'car' + index + 1,
                image: null,
                draggable: true,
                text: element.nome,
                tipoVeiculo: element.codTipoVeiculo,
              });
            });
            console.log(this.cars);
            this.cars.forEach((element) => {
              const imageaux = new Image();
              //imageaux.setAttribute("crossorigin", "anonymous");
              imageaux.crossOrigin = 'Anonymous';
              imageaux.src = `https://bboneapp.s3.amazonaws.com/imgsPadrao/imgEventos/${
                element.tipoVeiculo
              }/${cont[element.tipoVeiculo]}.png`;
              imageaux.width = 50;
              imageaux.height = 50;
              element.imgAux = imageaux.src;
              cont[element.tipoVeiculo] = cont[element.tipoVeiculo] + 1;
              imageaux.onload = () => {
                element.image = imageaux;
              };
            });
            console.log(this.cars);
          }
        };
      } //else this.preencheForm();
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    uploadCroqui(blob) {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        this.files.set('file', blob, 'croqui.png');

        service
          .upload('Upload', 'Eventos/' + this.evento.idEvento, this.files)
          .then((response) => {
            this.carregando = false;
            this.form.croqui = response;
            console.log(response);
            resolve(response);
          })
          .catch(function (error) {
            this.carregando = false;
            reject();
          });
      });
    },
    onSubmit() {
      var data = this.$refs.stage.getStage().toDataURL();
      var blob = canvasToBlob(data);
      this.uploadCroqui(blob).then((res) => {
        console.log(this.form);
        this.$emit('send', this.form);
      });
    },
    avancarPasso() {
      this.$router.push(`/editarEvento/${this.evento.idEvento}/8`);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
  },
  mounted() {
    //this.preencheForm();
    this.$validator.localize('en', locale);
  },
};
</script>

<style lang="scss">
.konvajs-content {
  background-image: url('https://bboneapp.s3.amazonaws.com/imgsPadrao/imgEventos/bg-rua.png');

  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
