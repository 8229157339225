<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <div class="form">
        <h4 class="my-2 mb-4">Partes Danificadas</h4>
        <b-row
          ><b-col class="container-img">
            <div class="teste">
              <div class="alert alert-info">
                Imagem 360° - arraste para o lado para ver todos os números
              </div>
              <vue-three-sixty
                :amount="8"
                :spinReverse="true"
                :imagePath="`https://bboneapp.s3.amazonaws.com/imgsPadrao/imgEventos/360/${idTipoCarro}`"
                fileName="{index}.png"
              />
            </div>
          </b-col>
        </b-row>

        <b-form @submit.stop.prevent="onSubmit" class="text-left">
          <b-list-group class="mb-3">
            <b-list-group-item
              v-for="item in partes"
              :variant="item.selecionado ? 'success' : ''"
              :key="item.id"
            >
              <b-row @click="selecionarParte(item)">
                <b-col cols="10">
                  {{ item.numeroLegenda }}- {{ item.descricao }}
                </b-col>
                <b-col class="text-right">
                  <i v-if="!item.selecionado" class="fa fa-check mr-2"></i>

                  <i v-else class="fa fa-times mr-2"></i>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-2"
                label="Outras Partes"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  class="mb-3"
                  v-model="form.outrasPartesDanificadas"
                  rows="3"
                  max-rows="6"
                  :name="`Outras Partes Danificadas`"
                  placeholder="Caso não tenha encontrado uma ou mais partes na lista acima, digite aqui"
                  :state="validateState(`Outras Partes Danificadas`)"
                  :class="validateClass(`Outras Partes Danificadas`)"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            pill
            ref="btnAvancar"
            size="lg"
            type="submit"
            variant="info"
            class="btn btn-block mb-5"
            :style="`background-color:${temaCor.botaoFormularios}; margin-bottom:200px`"
            >Salvar</b-button
          >
        </b-form>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import service from '../../services';
import moment from 'moment';
import locale from './../../locale';
export default {
  name: 'DashBoard',
  props: ['evento', 'temaCor'],
  watch: {
    evento(value) {
      this.preencheForm();
    },
  },
  data() {
    return {
      render: false,
      decoded: {},
      menuOpen: false,
      carregando: false,
      idEvento: this.$route.params.idEvento,
      imgExemplo: null,
      partes: [],
      idTipoCarro: 1,
      form: {
        outrasPartesDanificadas: null,
      },
    };
  },
  components: {},
  methods: {
    returnCarrosel() {
      //return require("./../../assets/images/imgs-eventos/caminhao/01.jpeg");
    },
    changePage() {},
    selecionarParte(item) {
      item.selecionado = !item.selecionado;
    },
    async preencheForm() {
      if (this.evento && this.render == false) {
        await this.buscaPartesEnvolvidas();
        this.form.outrasPartesDanificadas = this.evento.outrasPartesDanificadas;
        this.idTipoCarro = this.evento.Eventos_Veiculo.codTipoVeiculo;
      } else this.preencheForm();
    },
    buscaPartesEnvolvidas() {
      return new Promise((resolve, reject) => {
        this.render = true;
        this.carregando = true;
        service
          .post('Eventos', 'BuscarPartesDanificadasAssociado', {
            idEvento: this.idEvento,
          })
          .then((res) => {
            this.carregando = false;
            this.partes = [];
            res.data.forEach((element) => {
              this.partes.push(element);
            });
            console.log(this.partes);

            this.initImage();
            resolve();
          })
          .catch((e) => {
            this.carregando = false;
            reject();
          });
      });
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        var aux = this.partes.filter((x) => x.selecionado == true);
        if (aux.length > 0 || this.form.outrasPartesDanificadas != null) {
          this.form.partes = aux;
          this.form.idEvento = this.evento.idEvento;
          this.form.idVeiculo = this.evento.idVeiculo;
          this.$emit('send', this.form);
        } else {
          this.$bvToast.toast(
            'É necessário selecionar ao menos uma parte danificada',
            {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            },
          );
        }
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    initImage() {
      // vue-three-sixty: workaround to show the image on init
      const buttons = document.getElementsByClassName('v360-menu-btns');
      if (buttons.length) {
        buttons[buttons.length - 1].click(); // dispatch a click event to show image on init
      }
    },
  },
  mounted() {
    if (this.evento.idEvento) {
      console.log(this.evento);
      this.preencheForm();
    }
    this.$validator.localize('en', locale);
  },
};
</script>

<style lang="scss">
.teste {
  max-width: 100%;
  .v360-fullscreen-toggle {
    display: none !important;
  }
}
</style>
