<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <div class="form">
        <b-form class="text-left">
          <div class="img">
            <div v-if="necessitaUpload == false" class="alert alert-success">
              Você já possui uma foto importada, para alterar por uma nova
              clique na camera.
            </div>
            <p
              v-else-if="!hasImage && !foto.motivoReprovacao"
              class="text-center"
            >
              {{ textoFoto }}:
            </p>
            <div v-if="foto.legenda" class="alert alert-warning legenda">
              {{ foto.legenda }}
            </div>
            <div class="containerimg">
              <div
                v-if="
                  foto.aprovada == false && foto.motivoReprovacao && !hasImage
                "
                class="text-center alert alert-danger"
              >
                <b>Foto Reprovada:</b>
                {{ foto.motivoReprovacao }}
                <Br />
                <img class="imgReprovada mt-2" :src="foto.nome" />
                <Br />
                <p class="mt-2">Refaça a foto como o exemplo abaixo:</p>
              </div>
              <b-button
                v-if="hasImage"
                pill
                ref="btnAvancar"
                size="lg"
                @click="uploadFoto()"
                variant="info"
                class="btn btn-block mb-5"
                :style="`background-color:${temaCor.botaoFormularios}; `"
                >Salvar</b-button
              >
              <img :src="imgUrl" />
            </div>
            <!--VideoCapture uploadUrl="<example-server-address.com>" v-model="videoUrl" /-->
            <image-uploader
              :key="foto.nomePosicao"
              :preview="false"
              :className="['fileinput', { 'fileinput--loaded': hasImage }]"
              capture="environment"
              :debug="1"
              :autoRotate="true"
              outputFormat="verbose"
              @input="setImage"
              @onUpload="startImageResize"
              @onComplete="endImageResize"
              :quality="0.5"
              :scaleRatio="0.5"
            >
              <label for="fileInput" slot="upload-label">
                <div class="containericone">
                  <font-awesome-icon
                    class="icone"
                    :icon="['fa', iconeCamera]"
                  />
                </div>
                <p class="legendaIcone">
                  {{
                    hasImage
                      ? 'Clique no Icone para Alterar'
                      : 'Clique no icone para ter acesso a Camera'
                  }}
                </p>
              </label>
            </image-uploader>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import service from '../../services';
import moment from 'moment';
import locale from './../../locale';
import ImageUploader from 'vue-image-upload-resize';
import canvasToBlob from 'blueimp-canvas-to-blob';
export default {
  name: 'DashBoard',
  props: ['foto', 'idEvento', 'temaCor'],
  watch: {},
  data() {
    return {
      enderecoImgPadrao: 'https://bboneapp.s3.amazonaws.com/imgsPadrao/',
      decoded: {},
      necessitaUpload: true,
      mostraErroGeo: false,
      menuOpen: false,
      imgUrl: '',
      hasImage: false,
      files: new FormData(),
      image: null,
      styleFoto: 'display:block',
      iconeCamera: 'camera',
      textoFoto: 'Tire uma Foto como o Exemplo abaixo',
      carregando: false,
      form: {
        teste: null,
      },
    };
  },
  components: { ImageUploader },
  methods: {
    atualizaFotoAtual() {
      this.styleFoto = 'display:block';
      if (this.foto.nome != null && this.foto.aprovada == null) {
        this.imgUrl = this.foto.nome;
        this.necessitaUpload = false;
      } else {
        this.necessitaUpload = true;
        this.imgUrl = `${this.enderecoImgPadrao}${this.foto.fotoPadrao}`;
      }

      console.log(this.foto.nomePosicao.indexOf('Video'));
      if (this.foto.nomePosicao.indexOf('Video') >= 0) {
        this.iconeCamera = 'video';
        this.textoFoto = 'Faça um vídeo Foto como o Exemplo abaixo';
      }
    },

    startImageResize() {
      this.carregando = true;
    },
    endImageResize() {
      this.carregando = false;
    },
    setImage: function (output) {
      this.necessitaUpload = true;
      this.hasImage = true;
      this.image = output;
      this.imgUrl = output.dataUrl;
      console.log(output);
      console.log('Info', output.info);
      console.log('Exif', output.exif);
      var blob = canvasToBlob(this.imgUrl);
      this.image.blob = blob;
      this.styleFoto = 'display:none';
    },
    uploadFoto() {
      return new Promise((resolve, reject) => {
        if (this.necessitaUpload == false && this.hasImage == false) {
          resolve(this.imgUrl);
          return;
        }
        if (this.hasImage == true) {
          this.carregando = true;
          console.log(this.image.info);
          this.files.set('file', this.image.blob, this.image.info.name);

          service
            .upload('Upload', 'Evento/' + this.idEvento, this.files)
            .then((response) => {
              this.carregando = false;
              this.hasImage = false;
              var obj = {
                imagem: this.image.info.name,
                foto: this.foto,
                url: response,
                coordenadas: this.form.cordenadas,
                ip: this.form.ipAceite,
              };

              console.log(obj);
              this.$emit('upload', obj);
              resolve(response);
            })
            .catch(function (error) {
              this.carregando = false;
              reject();
            });
        } else {
          this.$bvToast.toast('É necessário adicionar uma foto', {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });
        }
      });
    },
    preencheForm() {
      this.form.teste = this.evento.idEvento;
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.$emit('send', this.form);
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
  },
  mounted() {
    this.atualizaFotoAtual();
    this.$validator.localize('en', locale);
  },
};
</script>

<style>
video {
  width: 100%;
}

.iconeErro {
  color: red;
  font-size: 56px;
  margin: 20px 0;
}
.iconeSucesso {
  color: green;
  font-size: 56px;
  margin: 20px 0;
}
.img .containerimg {
  text-align: center;
}

.img img {
  max-height: 250px;
  max-width: 100%;
  border: 1px solid #e7e7e7;
  margin: 0 auto;
}

.img label {
  width: 100%;
  font-weight: normal;
}

.img .containericone {
  text-align: center;
  border-radius: 10px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
  width: min-content;
  margin: 25px auto;
}

.img .icone {
  font-size: 50px;
  color: #333;
}

#fileinput,
input[type='file'] {
  display: none !important;
}

.legendaIcone {
  text-align: center;
  font-size: 12px;
  margin-top: 25px;
  margin-bottom: 70px !important;
}

.imgReprovada {
  width: 50% !important;
}

.legenda {
  font-size: 12px;
}
</style>
